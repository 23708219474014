<template>

  <v-form v-if="item">
    <v-row>
        <v-col md=7>

          <v-select
            id="scopeInput"
            name="scope"
            label="Escopo"
            v-model="item.scope"
            item-text="name"
            item-value="value"
            :items="options"
            hint="escopo é o 'tipo do tipo"
          ></v-select>

          <v-textarea
            id="descriptionInput"
            name="description"
            v-model="item.description"
            label="Descrição"
            hint=""
          ></v-textarea>

          <v-text-field
            id="nameInput"
            name="name"
            v-model="item.name"
            label="Nome"
            hint="pode conter acentos e espaços"
          ></v-text-field>


          <v-text-field
            id="valueInput"
            name="value"
            v-model="item.value"
            label="Valor"
            hint="qualquer tipo de valor é aceito"
          ></v-text-field>

          <v-text-field
            id="iconInput"
            name="icon"
            v-model="item.icon"
            label="Icone"
            hint="olha a tabela no site do Google"
          ></v-text-field><v-icon>{{item.icon}}</v-icon>
          {{item.color}}
          <v-color-picker
            id="colorInput"
            name="color"
            v-model="item.color"
            class="ma-2"
            dot-size="30"
            hint="cor para identificação visual"
          ></v-color-picker> 
        </v-col>

        <v-col>

        </v-col>

    </v-row>

    <v-row>

        <back-button
          view="small"
        ></back-button>

        <save-button view="big" @save="submit()">
        </save-button>
    </v-row>


  </v-form>

</template>

<script>
    import BackButton from '../../components/BackButton.vue'
  import SaveButton from '../../components/SaveButton.vue'
  const {EventBus} = require('../../addons/event-bus.js')

export default {
    name: 'TypeForm',
    components: {
      BackButton,
      SaveButton
    },
    data() {
      return {
        options: [
          {name: 'Tags', value: 'tag'},
          {name: 'UFs', value: 'province'},
          {name: 'Escopos', value: 'scope'},
          {name: 'Paleta', value: 'palette'},
          {name: 'Portfolio', value: 'portfolio'},
          {name: 'Status do Caso', value: 'lawsuit-status'},
          {name: 'Status Legal do Caso', value: 'lawsuit-legal-status'},
          {name: 'Tipo de Custa', value: 'cost-type'},
          {name: 'Tipo de Taxa', value: 'fee-value'},
          {name: 'Status da Custa', value: 'cost-status'},
          {name: 'Portfolio', value: 'portfolio'},
          {name: 'Evento ', value: 'event'},
          {name: 'Status do Parceiro', value: 'partner-status'},
          {name: 'Status do Desembolso', value: 'disimbursement-status'},
        ]
      }
    },
    props: ['item'],
    methods: {
      submit () {
        EventBus.$emit('type-submitted')
      }
    }
  }
</script>
